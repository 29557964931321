import React, { FC } from "react";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";
import Heading from "components/Heading/Heading";
import { useTranslation } from 'react-i18next';

export interface TermsProps {}

const TermsPage: FC<TermsProps> = () => {

  const { t } = useTranslation();

  return (
    <OneButtonCommonLayout backtHref="/">
      <Heading isCenter>
        {t("TermsPage.title")}
      </Heading>

      <div>{t("TermsPage.desc")}</div>
    </OneButtonCommonLayout>
  )
};

export default TermsPage;