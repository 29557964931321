import React, { useCallback, useContext, useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
// import { API, Storage } from "aws-amplify";
// import config from '../../aws-exports';
// import { createSeafood } from "graphql/mutations";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import { Link } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import FormItem from "containers/PageAddListing1/FormItem";
// import Textarea from "shared/Textarea/Textarea";
// import Input from "shared/Input/Input";
import { API } from "aws-amplify";
import { AuthContext } from "context/authenticate";
import Table from "components/Table/Table";
import { useTranslation } from 'react-i18next';
import Spinner from "components/Spinner/Spinner";
import OneButtonCommonLayout from "containers/PageAddListing1/OneButtonCommonLayout";


export interface AdminPageProps {
  className?: string;
}

// const {
//   aws_user_files_s3_bucket_region: region,
//   aws_user_files_s3_bucket: bucket
// } = config;

// const PageSignUp: FC<AdminPageProps> = ({ className = "" }) => {
const AdminPage: any = ({ className = "" }) => {
  const { auth, email } = useContext(AuthContext);

  const { t } = useTranslation();

  const [loaded, setLoaded] = useState<any>(undefined);
  const [orderData, setOrderData] = useState<any[]>([]);

  const column = React.useMemo(() => [
    {
      Header: t('LoginPage.product'),
      accessor: "description"

    },
    {
      Header: t('LoginPage.total'),
      accessor: "amount"

    },
  ], []);

  useEffect(() => {

    if (auth) {
      fetchCustomerCharges();
    } else {
      setOrderData([]);
    };

  }, [email]);

  const fetchCustomerCharges = useCallback(async () => {
    try {
      if (email) {
        const res = await API.get('stripeListCharges', '/getAllCustomerCharges', {
          queryStringParameters: {
            email: email,
          }
        });

        let data = res.body.data.data;
        // console.log("List Charges Success:", data);

        if (data.length === 0) {
          setTimeout(() => {
            setLoaded(false);
          }, 500);
        } else {
          let formattedData: any[] = [];

          data.map((item:any) => {
            return formattedData.push({
              description: item.description.substring(item.description.indexOf('(') + 1,item.description.lastIndexOf("t )") + 2), 
              amount: `$${item.amount/100}.00`
            });
          });

          setOrderData(formattedData);

          setTimeout(() => {
            setLoaded(true);
          }, 500);
        }
      }

    } catch (err) {
      console.log("fetchCustomerChargesError:", err);
      setOrderData([]);

      setTimeout(() => {
        setLoaded(false);
      }, 500);
    };
  }, [email]);

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();

  //   try {
  //       if (!seafoodDetails.title || !seafoodDetails.price) return
  //       const res = await API.graphql({
  //           query: createSeafood,
  //           variables: {input: seafoodDetails},
  //           authMode: "AMAZON_COGNITO_USER_POOLS"
  //       })
  //       setSeafoodDetails({ title: "", description: "", image: "", price: "" })

  //       console.log('Success RES:', res);
  //   } catch (err) {
  //       console.log('error creating todo:', err)
  //   }
  // };

  // const handleImageUpload = async (e: any) => {
  //   e.preventDefault();
  //   const file = e.target.files[0];
  //   const extension = file.name.split(".")[1];
  //   const name = file.name.split(".")[0];
  //   const key = `images/${uuidv4()}${name}.${extension}`;
  //   const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
  //   try {
  //       // Upload the file to s3 with public access level. 
  //       await Storage.put(key, file, {
  //           level: 'public',
  //           contentType: file.type
  //       });
  //       // Retrieve the uploaded file to display
  //       const image = await Storage.get(key, { level: 'public' })
  //       setImage(image);
  //       setSeafoodDetails({ ...seafoodDetails, image: url });
  //   } catch (err) {
  //       console.log(err);
  //   }
  // };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      {/* <div className="container mb-24 lg:mb-32"> */}
        {/* <div className="max-w-lg mx-auto space-y-6 "> */}

          <Authenticator 
            signUpAttributes={[
              'email',
              'phone_number'
            ]}
          >
            {
              ({ user, signOut }) => {
                
                return (
                    <section>
                      <h2 
                        className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center"
                      >
                        {t("LoginPage.hello")}
                        {
                          user 
                            ? user.username
                            : ""
                        }
                      </h2>
                    
                      <div className="flex justify-center pb-14">
                        <ButtonPrimary
                          onClick={signOut}
                        >
                          {t("LoginPage.signOut")}
                        </ButtonPrimary>
                      </div>

                      {
                        loaded === undefined && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Spinner className="pt-12 pb-24 lg:py-32" />
                          </div>
                        )
                      }

                      {
                        loaded === true && (
                          <div className="min-h-screen bg-white-100 text-gray-900">
                            <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                              <div className="">
                                <h1 className="text-xl font-semibold">
                                  {t("LoginPage.tableTitle")}
                                </h1>
                              </div>
                              
                              <div className="mt-4">
                                <Table 
                                  columns={column}
                                  data={orderData}
                                />
                              </div>
                            </main>
                          </div>
                        )
                      }

                      {
                        loaded === false && (
                          <OneButtonCommonLayout
                            backtHref="/"
                          >
                            <>
                              <h2 
                                className="text-2xl font-semibold flex justify-center"
                              >
                                {t("LoginPage.noHistory")}
                              </h2>
                            </>
                          </OneButtonCommonLayout>
                        )

                      }
                    </section>
                )
              }
            }
          </Authenticator>
        {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default AdminPage;
