/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input)
  }
`;
export const createSeafood = /* GraphQL */ `
  mutation CreateSeafood(
    $input: CreateSeafoodInput!
    $condition: ModelSeafoodConditionInput
  ) {
    createSeafood(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      createdAt
      updatedAt
      orders {
        items {
          id
          seafood_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
    }
  }
`;
export const deleteSeafood = /* GraphQL */ `
  mutation DeleteSeafood(
    $input: DeleteSeafoodInput!
    $condition: ModelSeafoodConditionInput
  ) {
    deleteSeafood(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      createdAt
      updatedAt
      orders {
        items {
          id
          seafood_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
    }
  }
`;
export const createSeafoodOrder = /* GraphQL */ `
  mutation CreateSeafoodOrder(
    $input: CreateSeafoodOrderInput!
    $condition: ModelSeafoodOrderConditionInput
  ) {
    createSeafoodOrder(input: $input, condition: $condition) {
      id
      seafood_id
      order_id
      order {
        id
        user
        date
        total
        createdAt
        updatedAt
        seafoods {
          nextToken
        }
        customer
      }
      createdAt
      updatedAt
      seafood {
        id
        title
        description
        image
        author
        featured
        price
        createdAt
        updatedAt
        orders {
          nextToken
        }
      }
      customer
    }
  }
`;
export const deleteSeafoodOrder = /* GraphQL */ `
  mutation DeleteSeafoodOrder(
    $input: DeleteSeafoodOrderInput!
    $condition: ModelSeafoodOrderConditionInput
  ) {
    deleteSeafoodOrder(input: $input, condition: $condition) {
      id
      seafood_id
      order_id
      order {
        id
        user
        date
        total
        createdAt
        updatedAt
        seafoods {
          nextToken
        }
        customer
      }
      createdAt
      updatedAt
      seafood {
        id
        title
        description
        image
        author
        featured
        price
        createdAt
        updatedAt
        orders {
          nextToken
        }
      }
      customer
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      createdAt
      updatedAt
      seafoods {
        items {
          id
          seafood_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      customer
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      createdAt
      updatedAt
      seafoods {
        items {
          id
          seafood_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      customer
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      createdAt
      updatedAt
      seafoods {
        items {
          id
          seafood_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      customer
    }
  }
`;
export const updateSeafood = /* GraphQL */ `
  mutation UpdateSeafood(
    $input: UpdateSeafoodInput!
    $condition: ModelSeafoodConditionInput
  ) {
    updateSeafood(input: $input, condition: $condition) {
      id
      title
      description
      image
      author
      featured
      price
      createdAt
      updatedAt
      orders {
        items {
          id
          seafood_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
    }
  }
`;
export const updateSeafoodOrder = /* GraphQL */ `
  mutation UpdateSeafoodOrder(
    $input: UpdateSeafoodOrderInput!
    $condition: ModelSeafoodOrderConditionInput
  ) {
    updateSeafoodOrder(input: $input, condition: $condition) {
      id
      seafood_id
      order_id
      order {
        id
        user
        date
        total
        createdAt
        updatedAt
        seafoods {
          nextToken
        }
        customer
      }
      createdAt
      updatedAt
      seafood {
        id
        title
        description
        image
        author
        featured
        price
        createdAt
        updatedAt
        orders {
          nextToken
        }
      }
      customer
    }
  }
`;
