import React, { FC, useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
// import { SeafoodContext } from "../../context/seafoods";
import { GoogleContext } from "context/google";
import { CartContext } from "../../context/cart";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { API, Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { processOrder } from "graphql/mutations";
import { useTranslation } from "react-i18next";

export interface CheckOutFormProps {
  className?: string;
  paymentTrigger: (string: any) => void;
  guestValues?: any;
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    },
    hidePostalCode: true
  }
};

const CheckoutForm: FC<CheckOutFormProps> = ({paymentTrigger, guestValues}) => {
  const { cart, total, clearCart } = useContext(CartContext);
  const { products } = useContext(GoogleContext);

  const [orderDetails, setOrderDetails] = useState<any>({ cart, total, address: null, token: null });
  const [error, setError] = useState<any>(null);

  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  // const history = useHistory();
  // console.log('Child component:', guestValues);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  //Add inventory
  const addInventory = async (keyValue: any, newValue: any) => {

    try {
      await API.post('googleAddSeafoodInventory', '/addSeafoodInventory', {
        queryStringParameters: {
          keyValue: keyValue,
          newValue: newValue
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } catch (err) {
      console.log('Update Error:', err);
    }
  };

  //Update google inventory API
  const updateSeafood = async (keyValue: any, newValue: any) => {

    try {
      await API.post('googleUpdateSeafoodInventory', '/updateSeafoodInventory', {
        queryStringParameters: {
          keyValue: keyValue,
          newValue: newValue
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        // .then((res) => {
        //   const result = res.body.data;
        //   console.log('Done:', result);
        // });
    } catch (err) {
      console.log('Update Error:', err);
    }
  };

  const findTheCorrectProducts = (allProducts: any, cartItems: any) => {
    let arr = [];

    for (let productRow = 0; productRow < allProducts.length; productRow++) {

      const eachProduct = allProducts[productRow];

      for (let cartRow = 0; cartRow < cartItems.length; cartRow++) {
        const eachCartItem = cartItems[cartRow];

        if (eachProduct.id === eachCartItem.id) {
          
          const currentQuantity = eachProduct.quantity;
          const deductQuantity = eachCartItem.amount;

          if (currentQuantity > deductQuantity || currentQuantity === deductQuantity) {
            arr.push({
              "id": eachCartItem.id,
              // "currentQuantity": currentQuantity,
              "deductQuantity": deductQuantity
            });

          } else {
            return [];
          }
        };
      };
    };
    return arr;
  };

  // Handle form submission.
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const res = findTheCorrectProducts(products, cart);

    if (res.length > 0) {

      const card = elements!.getElement(CardElement);
      const result = await stripe!.createToken(card as NonNullable<typeof card>);
      // console.log('Result:', result);

      // const paymentMethodReq = await stripe!.createPaymentMethod({
      //   type: "card",
      //   card: card
      // }as any);
      
      // const confirmedCardPayment = await stripe!.confirmCardPayment(clientSecret, {
      //   payment_method: paymentMethodReq.paymentMethod.id,
      // })

      if (result.error) {
        // Inform the user if there was an error.
        setError(result.error.message);
      } else {

        res.forEach(async (cartItem, index) => {
          await updateSeafood(cartItem.id, cartItem.deductQuantity);
        });
        setError(null);
        // Send the token to your server.
        const token = result.token;
        setOrderDetails({ ...orderDetails, 
          token: token.id, 
          guestName:  (guestValues && guestValues.name) || '',
          guestEmail:  (guestValues && guestValues.email) || '',
          guestPhone:  (guestValues && guestValues.phone) || ''
        });
        paymentTrigger("true");
        clearCart();
      }
    } else {
      alert("Something went wrong");
    };
  };

  async function isLoggedIn() {
    // Another way to get if its a guest or not
    //return await Auth.Credentials.getCredSource() === "guest"
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch {
        return false;
    }
  };

  const checkout = async (orderDetails: any) => {
    const payload = {
      id: uuidv4(),
      ...orderDetails
    };

    try {
      const res = await API.graphql({
        query: processOrder,
        variables: {input: payload},
        authMode: await isLoggedIn() ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
        // authMode: "AMAZON_COGNITO_USER_POOLS"
      })

      console.log("Order is successful:", res);
    } catch (err) {
      console.log("Checkout Error", err);
      paymentTrigger("false");

      setTimeout(() => {
        const res = findTheCorrectProducts(products, cart);

        res.forEach(async (cartItem, index) => {
          await addInventory(cartItem.id, cartItem.deductQuantity);
        });
        // console.log('Re-Add QTY');
      }, 3000)
      
    }
  };

  useEffect(() => {
    if (orderDetails.token) {
      checkout(orderDetails);
      //clearCart();
      //history.push("/");
    }
  }, [orderDetails]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="checkout-form">
        {/* <label htmlFor="checkout-address">Shipping Address</label>
        <input
          id="checkout-address"
          type="text"
          onChange={(e) => setOrderDetails({ ...orderDetails, address: e.target.value })}
        /> */}
        <div className="stripe-section">
          {/* <label htmlFor="stripe-element"> Credit or debit card </label> */}
          <CardElement id="stripe-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
        </div>
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>

      <div className="mt-10 flex justify-end">
        <ButtonPrimary type="submit">
          {t("CheckOutForm.submitButton")}
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default CheckoutForm;
