import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
// import pic1 from "../../images/geo1.jpg";
// import pic2 from "../../images/geo2.jpg";
// import pic3 from "../../images/geo3.jpg";
// import pic4 from "../../images/geo4.jpg";
// import pic5 from "../../images/geo5.jpg";
import { useTranslation } from "react-i18next";

export interface ProductCardProps {
  className?: string;
  ratioClass?: string;
  data?: any;
  size?: "default" | "small";
}

const ProductCard: FC<ProductCardProps> = ({
  size = "default",
  className = "",
  data = {},
  ratioClass,
}) => {
  
  // console.log('Props Data:', data);
  // const { id, title, price, image1, image2, image3, image4, image5 } = data;
  const { id, title, price, image1, image2 } = data;
  const { t } = useTranslation();

  const PHOTOS: string[] = [
    image1, 
    image2, 
    // image3, 
    // image4, 
    // image5
  ];

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider ratioClass={ratioClass} galleryImgs={PHOTOS} />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {listingCategory.name} · {bedrooms} beds
          </span> */}
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {/* {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )} */}
            {/* <span className="">{address}</span> */}
          </div>
        </div>
        <div className="w-100 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div 
          className="flex justify-between items-center"
          style={{justifyContent: 'end'}}
        >
          <span className="text-base font-semibold">
            {`$${price}.00`}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                {t("ProductsPage.perUnit")}
              </span>
            )}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ProductCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ProductCard"
    >
      <Link to={`/product-detail/${id}`}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ProductCard;