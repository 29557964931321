import React, { FC, useContext } from "react";
// import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import { Link } from "react-router-dom";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import { useTranslation } from 'react-i18next';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { AuthContext } from "../../context/authenticate";


export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const { i18n, t } = useTranslation();

  const { auth, userName } = useContext(AuthContext);

  const switchLanguageHandler = (language: any) => {
    i18n.changeLanguage(language);
  };

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <div>
              <button onClick={() => switchLanguageHandler("en")}>EN</button>
              <span>/</span>
              <button onClick={() => switchLanguageHandler("zh")}>中</button>
            </div>

            <button 
              className="text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center"
            >
              <Link to='/cart'>
                <ShoppingCartIcon className="w-8 h-8" />
              </Link>
            </button>
            {/* <SwitchDarkMode /> */}
            {/* <SearchDropdown /> */}
            {/* <div className="px-1" /> */}
            {
                auth
                  ?
                    <ButtonSecondary href="/login">
                      {t("LoginPage.hello")}
                      {userName}
                    </ButtonSecondary>
                  :
                    <ButtonPrimary href="/login"> 
                      {t("NavItems.signIn")}
                    </ButtonPrimary>
              }
          </div>
          <div className="flex items-center xl:hidden">
            <ShoppingCartIcon />
            {/* <SwitchDarkMode /> */}
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
