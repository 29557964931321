import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import AdminPage from "containers/AdminPage/AdminPage"
import WhatWeOfferPage from "containers/WhatWeOfferPage/WhatWeOfferPage";
import ProductsPage from "containers/ProductsPage/ProductsPage";
import ProductDetailPage from "containers/ProductsPage/ProductDetailPage";
import Cart from "containers/CartPage/CartPage";
import TermsPage from "containers/TermsPage/TermsPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/checkout", component: CheckOutPage },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/login", component: AdminPage },
  { path: "/products", component: WhatWeOfferPage },
  { path: "/shop", component: ProductsPage },
  { path: "/product-detail/:id", component: ProductDetailPage },
  { path: "/cart", component: Cart},
  { path: "/terms", component: TermsPage }
];

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
