/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a17f7350-589c-4150-b3fd-9eb30a8cf7a2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0y0P33zKI",
    "aws_user_pools_web_client_id": "7a1t63ksgtuvdqtofhb4l5t07e",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bhseafoodtemplate058e7a021a634dacb2dba2de0d741000505-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://enqywbitljenlcvsp5lwvqlz7m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-q5vddoopijbs5odnndhvpkyh6e",
    "aws_cloud_logic_custom": [
        {
            "name": "googleGetAllSeafood",
            "endpoint": "https://rz1juy43ik.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "googleGetOneSeafood",
            "endpoint": "https://h57qawomgl.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "googleUpdateSeafoodInventory",
            "endpoint": "https://3b4xrsyjlf.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "googleJoinEmailList",
            "endpoint": "https://uxl42q0r15.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "googleAddSeafoodInventory",
            "endpoint": "https://evzra67q2e.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "stripeListCharges",
            "endpoint": "https://ctin5b5tla.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
