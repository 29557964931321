import React, { useState } from "react";
// import { Auth, API } from "aws-amplify";
// import { v4 as uuidv4 } from "uuid";
// import { processOrder } from "../graphql/mutations";


interface googleInterface{
  products: any[];
  setProducts: (products: any) => void;
  checkout?: (orderDetails: any) => void;
};

const defaultState = {
  products: [],
  setProducts: () => {},
  checkout: () => {}
};

const GoogleContext = React.createContext<googleInterface>(defaultState);

const GoogleProvider: React.FC = ({ children }) => {

  const [products, setProducts] = useState<Array<object>>([]);

  // async function isLoggedIn() {
  //   // Another way to get if its a guest or not
  //   //return await Auth.Credentials.getCredSource() === "guest"
  //   try {
  //       await Auth.currentAuthenticatedUser();
  //       return true;
  //   } catch {
  //       return false;
  //   }
  // };

  // const checkout = async (orderDetails: any) => {
  //   const payload = {
  //     id: uuidv4(),
  //     ...orderDetails
  //   };

  //   try {
  //     const res = await API.graphql({
  //       query: processOrder,
  //       variables: {input: payload},
  //       // authMode: await isLoggedIn() ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  //       authMode: "AMAZON_COGNITO_USER_POOLS"
  //     })

  //     console.log("Order is successful:", res);
  //   } catch (err) {
  //     console.log("Checkout Error", err);

  //     console.log("whats in the cart:", cart);
  //     const res = findTheCorrectProducts(products, cart);

  //     res.forEach(async (cartItem, index) => {
  //       await addInventory(cartItem.id, cartItem.deductQuantity);
  //     });
  //     console.log('Re-Add QTY');
  //   }
  // };

  return(
    <GoogleContext.Provider
      value={{ products, setProducts }}
    >
        {children}
    </GoogleContext.Provider>
  )
};

export { GoogleContext, GoogleProvider };