import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionHero from "./SectionHero";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import PageContact from "containers/PageContact/PageContact";
import { useTranslation } from 'react-i18next';
// import AboutUsBanner from "components/AboutUsBanner/AboutUsBanner";
import NcImage from "shared/NcImage/NcImage";
import boatImage from "images/aboutUsBanner.jpg";
// import rightImage from "images/store.jpg";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {

  const { t } = useTranslation();

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>{"Best Honour Seafood"}</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={boatImage}
      />

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        {/* <SectionHero
          image1={rightImage}
          image2={rightImage1}
          heading={t("AboutUsPage.title")}
          btnText=""
          subHeading={t("AboutUsPage.subHeading")}
        /> */}
        {/* <AboutUsBanner banner1={rightImage1} banner2={rightImage} /> */}

        <div
          className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <h3>{t("AboutUsPage.title")}</h3>
          <p>{t("AboutUsPage.subHeading")}</p>

          <h3>{t("WhatWeOffer.sustain.title")}</h3>
          <p>{t("WhatWeOffer.sustain.content1")}</p>

          <h3>{t("WhatWeOffer.sustain.content2")}</h3>
          <p>{t("WhatWeOffer.sustain.content3")}</p>
        </div>

        {/* <PageContact /> */}
        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic />

        <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
