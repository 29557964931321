import React, {FC} from "react";
// import { DEMO_POSTS } from "data/posts";
// import { PostDataType } from "data/types";
// import { Link } from "react-router-dom";
// import Avatar from "shared/Avatar/Avatar";
// import Badge from "shared/Badge/Badge";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
// import SocialsList from "shared/SocialsList/SocialsList";
// import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import fishing from "../../images/whatWeOfferBanner.jpg";
import geo from "../../images/DSC_6744.jpg";
import seaCucumber from "../../images/SC2.jpg";
import spotPrawn from "../../images/spotPrawn.jpg";
import crabImage from "../../images/batchKingCrab2.jpg";
import razorClam from "../../images/razorclam.jpg";
import abalone from "../../images/Mix2.jpg";
import propClam from "../../images/prop1.jpg";

export interface Props {}

const WhatWeOfferPage: FC<Props> = () => {

  const { t } = useTranslation();

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h3>{t("WhatWeOffer.geoduck.title")}</h3>
        <p>{t("WhatWeOffer.geoduck.content1")}</p>
        <img
          src={geo}
          alt="nc blog"
          className="rounded-2xl"
        />

        <h3>{t("WhatWeOffer.seaCucumber.title")}</h3>
        <p>{t("WhatWeOffer.seaCucumber.content1")}</p>

        <img
          src={seaCucumber}
          alt="nc blog"
          className="rounded-2xl"
        />

        {/* <blockquote>
          <p>
            Typography is pretty important if you don't want your stuff to look
            like trash. Make it good then it won't be bad.
          </p>
        </blockquote> */}
        
        <h3>{t("WhatWeOffer.spotPrawn.title")}</h3>
        <p>{t("WhatWeOffer.spotPrawn.content1")}</p>

        {/* <figure> */}
          <img
            src={spotPrawn}
            alt="nc blog"
            className="rounded-2xl"
          />
          {/* <figcaption>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
            officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
            distinctio veritatis sapiente
          </figcaption>
        {/* </figure> */}
        {/* <ul>
          <li>So here is the first item in this list.</li>
          <li>In this example we're keeping the items short.</li>
          <li>Later, we'll use longer, more complex list items.</li>
        </ul> */}
        <h3>{t("WhatWeOffer.kingCrab.title")}</h3>
        <p>{t("WhatWeOffer.kingCrab.content1")}</p>
        <img
          src={crabImage}
          alt="nc blog"
          className="rounded-2xl"
        />

        <h3>{t("WhatWeOffer.razorClam.title")}</h3>
        <p>{t("WhatWeOffer.razorClam.content1")}</p>
        <img
          src={razorClam}
          alt="nc blog"
          className="rounded-2xl"
        />

        <h3>{t("WhatWeOffer.abalone.title")}</h3>
        <p>{t("WhatWeOffer.abalone.content1")}</p>
        <img
          src={abalone}
          alt="nc blog"
          className="rounded-2xl"
        />

        <h3>{t("WhatWeOffer.propellorClams.title")}</h3>
        <p>{t("WhatWeOffer.propellorClams.content1")}</p>
        <img
          src={propClam}
          alt="nc blog"
          className="rounded-2xl"
        />

        <h3>{t("WhatWeOffer.conclusion.title")}</h3>
        <p>{t("WhatWeOffer.conclusion.content1")}</p>
      </div>
    );
  };

  // const renderTags = () => {
  //   return (
  //     <div className="max-w-screen-md mx-auto flex flex-wrap">
  //       <a
  //         className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
  //         href="##"
  //       >
  //         Garden
  //       </a>
  //       <a
  //         className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
  //         href="##"
  //       >
  //         Jewelry
  //       </a>
  //       <a
  //         className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
  //         href="##"
  //       >
  //         Tools
  //       </a>
  //     </div>
  //   );
  // };

  // const renderAuthor = () => {
  //   return (
  //     <div className="max-w-screen-md mx-auto ">
  //       <div className="nc-SingleAuthor flex">
  //         <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
  //         <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
  //           <span className="text-xs text-neutral-400 uppercase tracking-wider">
  //             WRITEN BY
  //           </span>
  //           <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
  //             <a href="/ncmaz/author/the-demo-author-slug">Fones Mimi</a>
  //           </h2>
  //           <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
  //             There’s no stopping the tech giant. Apple now opens its 100th
  //             store in China.There’s no stopping the tech giant.
  //             <a
  //               className="text-primary-6000 font-medium ml-1"
  //               href="/ncmaz/author/the-demo-author-slug"
  //             >
  //               Readmore
  //             </a>
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderCommentForm = () => {
  //   return (
  //     <div className="max-w-screen-md mx-auto pt-5">
  //       <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
  //         Responses (14)
  //       </h3>
  //       <form className="nc-SingleCommentForm mt-5">
  //         <Textarea />
  //         <div className="mt-2 space-x-3">
  //           <ButtonPrimary>Submit</ButtonPrimary>
  //           <ButtonSecondary>Cancel</ButtonSecondary>
  //         </div>
  //       </form>
  //     </div>
  //   );
  // };

  // const renderCommentLists = () => {
  //   return (
  //     <div className="max-w-screen-md mx-auto">
  //       <ul className="nc-SingleCommentLists space-y-5">
  //         <li>
  //           <Comment />
  //           <ul className="pl-4 mt-5 space-y-5 md:pl-11">
  //             <li>
  //               <Comment isSmall />
  //             </li>
  //           </ul>
  //         </li>
  //         <li>
  //           <Comment />
  //           <ul className="pl-4 mt-5 space-y-5 md:pl-11">
  //             <li>
  //               <Comment isSmall />
  //             </li>
  //           </ul>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // };

  // const renderPostRelated = (post: PostDataType) => {
  //   return (
  //     <div
  //       key={post.id}
  //       className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
  //     >
  //       <Link to={post.href} />
  //       <NcImage
  //         className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
  //         src={post.featuredImage}
  //       />
  //       <div>
  //         <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
  //       </div>
  //       <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
  //         <Badge name="Categories" />
  //         <h2 className="block text-lg font-semibold text-white ">
  //           <span className="line-clamp-2">{post.title}</span>
  //         </h2>

  //         <div className="flex">
  //           <span className="block text-neutral-200 hover:text-white font-medium truncate">
  //             {post.author.displayName}
  //           </span>
  //           <span className="mx-1.5 font-medium">·</span>
  //           <span className="font-normal truncate">{post.date}</span>
  //         </div>
  //       </div>
  //       <Link to={post.href} />
  //     </div>
  //   );
  // };

  return (
    // <div className="nc-PageSingle pt-8 lg:pt-16 ">
    <div className="nc-PageSingle">
      <Helmet>
        <title>Best Honour Seafood</title>
      </Helmet>

      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={fishing}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>

      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default WhatWeOfferPage;
