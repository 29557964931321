import React, { FC, useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import NcImage from "shared/NcImage/NcImage";
// import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import { useParams, useHistory } from "react-router-dom";
import { CartContext } from "context/cart";
import { API } from "aws-amplify";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Spinner from "components/Spinner/Spinner";
import Banner from "components/Banner/Banner";

export interface ListingProductPageProps {
  className?: string;
  isPreviewMode?: boolean;
};

const ListingStayDetailPage: FC<ListingProductPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  
  const { id } = useParams() as any;
  const history = useHistory();
  // const { products } = useContext(GoogleContext);
  const { addToCart } = useContext(CartContext);
  const { t } = useTranslation();

  // const [isOpen, setIsOpen] = useState(false);
  // const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [chosenSeafood, setChosenSeafood] = useState({
    title: '',
    description: '',
    price: '',
    quantity: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
  });
  const [soldOut, setSoldOut] = useState<any>(null);
  const [loaded, setLoaded] = useState<any>(undefined);

  useEffect(() => {
    getOneSeafood(id);
  }, []);

  useEffect(() => {
    setSoldOut(checker(chosenSeafood));
  }, [chosenSeafood, soldOut]);

  const getOneSeafood = async (id: any) => {
    try {

      const res = await API.get('googleGetOneSeafood', '/getOneSeafood', {
        queryStringParameters: {
          rowIndex: id,
        }
      });

      const result = res.body.data;
      // console.log('getOne Res:', result);
      
      setChosenSeafood(chosenSeafood => ({
        ...chosenSeafood,
        title: result.title,
        description: result.description,
        price: result.price,
        quantity: result.quantity,
        image1: result.image1,
        image2: result.image2,
        image3: result.image3,
        image4: result.image4,
        image5: result.image5
      }));

      setTimeout(() => {
        setLoaded(true);
      }, 500);

    } catch(err) {
      console.log('Get One Error:', err);
    }

  };

  let arr = [];

  for (let [key, value] of Object.entries(chosenSeafood)) {
    if (key !== undefined && value !== undefined) {
      arr.push(value);
    }
  }

  let pattern = "https://drive.google.com";
  let filtered = arr.filter((str: any) => { return str.includes(pattern); })
    
  // console.log('Filtered:', filtered);

  const checker = (input: any) => {
    let outOfStock;

    if (input.quantity === "0" || input.quantity === "") {
      outOfStock = true;
    } else if (input.quantity === "-1") {
      outOfStock = undefined;
    } else {
      outOfStock = false;
    };

    return outOfStock;
  };

  // const seafood = seafoods.find((seafood) => {
  //   return seafood.id === id;
  // });

  // if (!seafood) {
  //   return <h3>Loading...</h3>;
  // }

  // const { image: url, title, description, author, price } = seafood;

  // const product:any = products.find((product) => {
  //   return product.id === id;
  // });

  // const { price, title, description } = product;

  // const finalPrice = (price: any) => {
  //   let total;
  //   total = price * 1.05
  //   return Math.round(total * 100)/100;
  // };

  // const handleOpenModal = (index: number) => {
  //   setIsOpen(true);
  //   setOpenFocusIndex(index);
  // };

  // const handleCloseModal = () => setIsOpen(false);

  const renderDescription = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{chosenSeafood.title}</h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {chosenSeafood.description}
          </span>
        </div>
      </div>
    );
  };

  const renderSalesPolicy = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {t("ProductsPage.detail.salesPolicyTitle")}
        </h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}
        <div>
          {/* <h4 className="text-lg font-semibold">Cancellation policy</h4> */}
          <span className="block text-neutral-500 dark:text-neutral-400">
            {t("ProductsPage.detail.salesPolicyDesc1")}
          </span>

          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {t("ProductsPage.detail.salesPolicyDesc2")}
          </span>
        </div>
      </div>
    );
  };

  const renderInternational = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {t("ProductsPage.detail.internationalTitle")}
        </h2>
        <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}
        <div>
          {/* <h4 className="text-lg font-semibold">Cancellation policy</h4> */}
          <span className="block text-neutral-500 dark:text-neutral-400">
            {t("ProductsPage.detail.internationalDesc1")}
          </span>

          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {t("ProductsPage.detail.internationalDesc2")}
          </span>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {`$${chosenSeafood.price}.00`}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              {t("ProductsPage.detail.perUnit")}
            </span>
          </span>
          {/* <StartRating /> */}
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("ProductsPage.detail.subTotal")}</span>
            <span>{`$${chosenSeafood.price}.00`}</span>
          </div>
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("ProductsPage.detail.salesTax")}</span>
            <span>5% Gst</span>
          </div> */}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("ProductsPage.detail.total")}</span>
            <span>{`$${chosenSeafood.price}`}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <>
          {
            soldOut === true && (
              <ButtonSecondary>{t("ProductsPage.detail.soldOutButton")}</ButtonSecondary>
            )
          }
          {
            soldOut === false && (
              <ButtonPrimary
                onClick={() => {
                  addToCart({ ...chosenSeafood, id });
                  history.push("/cart");
                }}
              >
                {t("ProductsPage.detail.addCartButton")}
              </ButtonPrimary>
            )
          }

          {soldOut === undefined && (
            <ButtonSecondary>{t("ProductsPage.detail.inquireButton")}</ButtonSecondary>
          )}
        </>
      </div>
    );
  };
  
  return (
      <div
        className={`nc-ListingStayDetailPage  ${className}`}
        data-nc-id="ListingStayDetailPage"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {
          loaded === undefined && (
            <Spinner className="pt-24 pb-24 lg:py-32" />
          )
        }
        
        {
          loaded === true && (
            <>
              {/* SINGLE HEADER */}

                <>
                  <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                      {/* <NcImage
                        className="w-full rounded-xl"
                        containerClassName="container my-10 sm:my-12 "
                        src={PHOTOS[0]}
                      /> */}

                      <Banner imageArr={filtered} />
                    {/* <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2"> */}
                      {/* <div
                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                        onClick={() => handleOpenModal(0)}
                      >
                        <NcImage
                          containerClassName="absolute inset-0"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl"
                          src={PHOTOS[0]}
                          prevImageHorizontal
                        />
                        <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                      </div> */}

                      {/* {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                          key={index}
                          className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                            index >= 3 ? "hidden sm:block" : ""
                          }`}
                        >
                          <NcImage
                            containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                            className="object-cover w-full h-full rounded-md sm:rounded-xl "
                            src={item || ""}
                            prevImageHorizontal
                          />

                          <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                            onClick={() => handleOpenModal(index + 1)}
                          />
                        </div>
                      ))} */}

                      {/* <div
                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                        onClick={() => handleOpenModal(0)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                          />
                        </svg>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                          {t("ProductsPage.detail.showPics")}
                        </span>
                      </div> */}
                    {/* </div> */}
                  </header>
                  {/* MODAL PHOTOS */}
                  {/* <ModalPhotos
                    imgs={PHOTOS}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    initFocus={openFocusIndex}
                  /> */}
                </>

                {/* MAIn */}
                <main className="container mt-11 flex ">
                  {/* CONTENT */}
                  <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                    {renderDescription()}
                    {renderSalesPolicy()}
                    {renderInternational()}
                  </div>

                  {/* SIDEBAR */}
                  <div className="hidden lg:block flex-grow">
                    <div className="sticky top-24">{renderSidebar()}</div>
                  </div>
                </main>

                {/* STICKY FOOTER MOBILE */}
                {!isPreviewMode && (
                  <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
                    <div className="container flex items-center justify-between">
                      <span className="text-2xl font-semibold">
                        {`$${chosenSeafood.price}`}
                        <span 
                          className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400"
                        >
                          {t("ProductsPage.detail.perUnit")}
                        </span>
                      </span>

                      <>
                        {
                          soldOut === true && (
                            <ButtonSecondary>{t("ProductsPage.detail.soldOutButton")}</ButtonSecondary>
                          )
                        }
                        {
                          soldOut === false && (
                            <ButtonPrimary
                              onClick={() => {
                                addToCart({ ...chosenSeafood, id });
                                history.push("/cart");
                              }}
                            >
                              {t("ProductsPage.detail.addCartButton")}
                            </ButtonPrimary>
                          )
                        }
                        {soldOut === undefined && (
                          <ButtonSecondary>{t("ProductsPage.detail.inquireButton")}</ButtonSecondary>
                        )}
                      </>
                    </div>
                  </div>
                )}
            </>
          )
        }
      </div>
  );
};

export default ListingStayDetailPage;