import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import SectionGridFilterCard from "./SelectionGridFilterCard";
import { GoogleContext } from "context/google";
import { API } from "aws-amplify";
import Spinner from "components/Spinner/Spinner";

export interface ProductsPageProps {
  className?: string;
};

const ProductsPage: FC<ProductsPageProps> = ({className = ""}) => {

  const { setProducts } = useContext(GoogleContext);

  const [loaded, setLoaded] = useState<any>(undefined);

  useEffect(() => {
    fetchAllGoogleProducts();
  }, []);

  const fetchAllGoogleProducts = useCallback(async () => {
    try {
      const res = await API.get('googleGetAllSeafood', '/getAllSeafood', {});

      setProducts(res.body.data);

      setTimeout(() => {
        setLoaded(true);
      }, 500);

      // console.log("Get all google products from ProductPage:", res.body.data);
    } catch (err) {
      console.log("fetchAllGoogleError:", err);
    }
  }, [setProducts]);

  return(
    <div
      className={`nc-ProductsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <div 
        className="container relative overflow-hidden"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {
          loaded === undefined && (
            <Spinner className="pt-24 pb-24 lg:py-32" />
          )
        }

        {
          loaded === true && (
            <SectionGridFilterCard className="pb-24 lg:pb-32" />
          )
        }
      </div>

     
    </div>
  )
};

export default ProductsPage;