import React, { useState } from "react";

interface AnyInterface{
  auth: boolean;
  setAuth: (boolean: boolean) => void;
  userName: string;
  setUserName: (string: string) => void;
  email: string;
  setEmail: (string: string) => void;
};

const defaultState = {
  auth: false,
  setAuth: () => {},
  userName: '',
  setUserName: () => {},
  email: '',
  setEmail: () => {}
};

const AuthContext = React.createContext<AnyInterface>(defaultState);

const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<boolean>(false);
  const [userName, setUserName] = useState<any>('');
  const [email, setEmail] = useState<any>('');

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, userName, setUserName, email, setEmail }}
    >
      {children}
    </AuthContext.Provider>
  )
};

export { AuthContext, AuthProvider };