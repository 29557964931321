import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/KingCrab.jpg";
import NcImage from "shared/NcImage/NcImage";
// import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { useTranslation } from "react-i18next";
import API from "@aws-amplify/api";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const { t } = useTranslation();

  const [values, setValues] = useState({
    email: '',
  });

  const [sent, setSent] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setValues((prevState) => ({ ...prevState, [name]: value }))
  };

  const postEmail = async (rowValue: any) => {
    try {

      await API.post('googleJoinEmailList', '/postEmail', {
        queryStringParameters: {
          rowValue: rowValue
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setSent(true);
    } catch (err) {
      console.log('Post Email Error:', err);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    postEmail(values.email);
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">
          {t("HomePage.subscribe.title")}
        </h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          {t("HomePage.subscribe.subTitle")}
        </span>
        {/* <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {t("HomePage.subscribe.desc1")}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {t("HomePage.subscribe.desc2")}
            </span>
          </li>
        </ul> */}

        {
          sent 
            ?
              <div
                className="font-medium text-neutral-700 dark:text-neutral-300 mt-10"
              >
                {t("HomePage.subscribe.receive")}
              </div>
            :
              <form 
                className="mt-10 relative max-w-sm"
                onSubmit={handleSubmit}
              >
                <Input
                  required
                  aria-required
                  placeholder={t("HomePage.subscribe.input")}
                  name="email"
                  onChange={handleChange}
                  type="email"
                />
                <ButtonCircle
                  type="submit"
                  className="absolute transform top-1/2 -translate-y-1/2 right-1"
                >
                  <i className="las la-arrow-right text-xl"></i>
                </ButtonCircle>
              </form>
        }
      </div>
      
      <div className="flex-grow">
        <NcImage src={rightImg} style={{borderRadius: '16px'}}/>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
