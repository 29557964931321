import React, { useCallback, useEffect, useContext } from "react";
import MyRouter from "routers/index";
// AMPLIFY
import Amplify, { Auth, Hub } from "aws-amplify";
import awsExports from "./aws-exports";
import { AuthContext } from "../src/context/authenticate";
Amplify.configure(awsExports);


function App() {

  const { setAuth, setUserName, setEmail } = useContext(AuthContext);

  const isLoggedIn = useCallback(async () => {
    try {
      const result = await Auth.currentAuthenticatedUser();
      // console.log('Logged in:', result);
      setAuth(true);
      setUserName(result.username);
      setEmail(result.attributes.email);
    } catch (err) {
      // console.log('Not logged in:', err);
    };
  }, []);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data
      // console.log('A new auth event has happened: ', data)
       if (payload.event === 'signIn') {
        //  console.log('a user has signed in!');

         isLoggedIn();
       }
       if (payload.event === 'signOut') {
        //  console.log('a user has signed out!')
         setAuth(false);
       }
    })
  }, []);

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
