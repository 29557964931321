import React, { FC } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import imagePng from "images/bannerShell.jpg";
import singleGeo from "images/singleGeo.jpg";
// import geoBanner from "images/geoBanner.jpg"
// import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { useTranslation } from "react-i18next";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {

  const { t } = useTranslation();

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      style={{marginTop: '0'}}
      data-nc-id="SectionHero"
    >
      {/* <div className="flex flex-col lg:flex-row"> */}
      <div className="flex flex-col lg:flex-row lg:items-center">
        {/* <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pt-11 pb-14 lg:pb-60 xl:pr-14 lg:mr-10 xl:mr-0"> */}
        {/* <div 
          className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0"
        > */}
        <div
          className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 xl:pr-14 lg:mr-10 xl:mr-0"
        >
          <h4 className="font-small md:text-3xl text-neutral-500 dark:text-neutral-400">
            {t("HomePage.bannerCTA.title")}
          </h4>
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {t("HomePage.bannerCTA.subTitle")}
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            
          </span>
          <ButtonPrimary>
            <Link to="/products">
              {t("HomePage.bannerCTA.button")}
            </Link>
          </ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" style={{borderRadius: '8px', objectFit: 'cover'}} src={singleGeo} alt="hero" />
        </div>
      </div>

      {/* <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div> */}
    </div>
  );
};

export default SectionHero;
