import React, { FC } from "react";
import { classNames } from "./Utils";

export interface Props {
  children: any,
  className?: any,
  onClick: () => void,
  disabled: boolean
}

export const Button: FC<Props> = ({ children, className, onClick, disabled, ...rest }) => {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}

export const PageButton: FC<Props> = ({ children, className, onClick, disabled, ...rest }) => {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}