import React from "react";
import ReactDOM from "react-dom";
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// AMPLIFY
// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
// Amplify.configure(awsExports);
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { GoogleProvider } from "context/google";
import { SeafoodProvider } from "./context/seafoods";
import { CartProvider } from "./context/cart";
import { AuthProvider } from "context/authenticate";
import './i18n';

ReactDOM.render(
  <React.Suspense fallback={'loading'}>
    <AuthProvider>
      <GoogleProvider>
        <SeafoodProvider>
          <CartProvider>
            <AmplifyProvider>
              <App />
            </AmplifyProvider>
          </CartProvider>
        </SeafoodProvider>
      </GoogleProvider>
    </AuthProvider>
  </React.Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
