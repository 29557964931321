import React from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
// import { listSeafoods } from "../graphql/queries";
import { processOrder } from "../graphql/mutations";

interface AnyInterface{
  // seafoods: any[];
  // featured: any[];
  // loading: boolean;
  checkout: (orderDetails: any) => void;
};

const defaultState = {
  // seafoods: [],
  // featured: [],
  // loading: false,
  checkout: () => {}
};

const SeafoodContext = React.createContext<AnyInterface>(defaultState);

const SeafoodProvider: React.FC = ({ children }) => {
  // const [seafoods, setSeafoods] = useState<Array<object>>([]);
  // const [featured, setFeatured] = useState<Array<object>>([]);
  // const [loading, setLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   fetchSeafoods();
  // }, []);
  
  const checkout = async (orderDetails: any) => {
    const payload = {
      id: uuidv4(),
      ...orderDetails
    };
    console.log('payload:', payload);
    try {
      // await API.graphql(graphqlOperation(processOrder, { input: payload }));

      const res = await API.graphql({
        query: processOrder,
        variables: {input: payload},
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })

      console.log("Order is successful:", res);
    } catch (err) {
      console.log("Checkout Error", err);
    }
  };

  // const fetchSeafoods = async () => {
  //   try {
  //     setLoading(true);
  //     // Switch authMode to API_KEY for public access
  //     const { data } = await API.graphql({
  //       query: listSeafoods,
  //       // authMode: "AMAZON_COGNITO_USER_POOLS"
  //       authMode: "API_KEY"
  //     }) as any;
  //     const seafoods = data.listSeafoods.items;
  //     const featured = seafoods.filter((seafood: any) => {
  //       return !!seafood.featured;
  //     });
  //     setSeafoods(seafoods);
  //     setFeatured(featured);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <SeafoodContext.Provider 
      value={{ checkout }}
    >
      {children}
    </SeafoodContext.Provider>
  );
};

export { SeafoodContext, SeafoodProvider };